
.form-pool {
  margin-top: 20px;
  .d-table {
    display: table;
    width: 100%;
    min-height: 48px;
    border-spacing: 2px;
    .d-table-row {
      text-align: center;
      display: table-row;
      .d-table-cell {
        backdrop-filter: blur(10px);
        background: rgba(128,128,128, 0.4);
        display: table-cell;
        vertical-align: middle;
        font-size: 14px;
        min-width: 25%;
        padding: 5px;
        &.answered {
          background: #fff;
          color: #333;
          border: 1px solid #333;
        }
        &:first-child {
          border-radius: 24px 0 0 24px;
          margin-right: 1%;
        }
        &:last-child {
          border-radius: 0 24px 24px 0;
          margin-left: 1%;
        }
      }
    }
  }
}
