
.feed-body {
  background-color: #fff;
  .feed-card {
    background-color: #f5f5f5;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    margin: 0 0 5px;
    border: none;
    border-radius: 0;
    box-shadow: none;
    h2 {
      margin: 10px 0;
      font-size: 18px;
    }
    h1 {
      margin: 0;
      padding-top: 15px;
      font-size: 24px;
    }
    .feed-card-content {
      padding: 5px 20px 20px;
      position: relative;
      width: 100%;
      height: calc(100vw * 1.25);
      .poll-block {
        padding: 0 20px;
        position: absolute;
        bottom: 65px;
        left: 0;
      }
      .feed-image-normal {
        margin: 5px -10px 0;
      }
      &.feed-card-content-normal {
        height: auto;
      }
      .feed-card-footer {
        display: block;
        z-index: 1000;
        position: absolute;
        width: calc(100% - 40px);
        bottom: 20px;
        &.feed-content-normal {
          position: relative;
          bottom: 0;
          margin: 2px 0 0;
          width: 100%;
        }
      }
    }
  }
}
