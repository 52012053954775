.ion-color-action {
  --ion-color-base: var(--ion-color-action);
  --ion-color-base-rgb: var(--ion-color-action-rgb);
  --ion-color-contrast: var(--ion-color-action-contrast);
  --ion-color-contrast-rgb: var(--ion-color-action-contrast-rgb);
  --ion-color-shade: var(--ion-color-action-shade);
  --ion-color-tint: var(--ion-color-action-tint);
}

.ion-color-ok {
  --ion-color-base: var(--ion-color-ok);
  --ion-color-base-rgb: var(--ion-color-ok-rgb);
  --ion-color-contrast: var(--ion-color-ok-contrast);
  --ion-color-contrast-rgb: var(--ion-color-ok-contrast-rgb);
  --ion-color-shade: var(--ion-color-ok-shade);
  --ion-color-tint: var(--ion-color-ok-tint);
}

.ion-color-facebook {
  --ion-color-base: var(--ion-color-facebook);
  --ion-color-base-rgb: var(--ion-color-facebook-rgb);
  --ion-color-contrast: var(--ion-color-facebook-contrast);
  --ion-color-contrast-rgb: var(--ion-color-facebook-contrast-rgb);
  --ion-color-shade: var(--ion-color-facebook-shade);
  --ion-color-tint: var(--ion-color-facebook-tint);
}
.ion-color-shiawasedo {
  --ion-color-base: var(--ion-color-shiawasedo);
  --ion-color-base-rgb: var(--ion-color-shiawasedo-rgb);
  --ion-color-contrast: var(--ion-color-shiawasedo-contrast);
  --ion-color-contrast-rgb: var(--ion-color-shiawasedo-contrast-rgb);
  --ion-color-shade: var(--ion-color-shiawasedo-shade);
  --ion-color-tint: var(--ion-color-shiawasedo-tint);
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-gift {
  --ion-color-base: var(--ion-color-gift);
  --ion-color-base-rgb: var(--ion-color-gift-rgb);
  --ion-color-contrast: var(--ion-color-gift-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gift-contrast-rgb);
  --ion-color-shade: var(--ion-color-gift-shade);
  --ion-color-tint: var(--ion-color-gift-tint);
}

.ion-color-shiawasedo-primary {
  --ion-color-base: var(--ion-color-shiawasedo-primary);
  --ion-color-base-rgb: var(--ion-color-shiawasedo-primary-rgb);
  --ion-color-contrast: var(--ion-color-shiawasedo-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-shiawasedo-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-shiawasedo-primary-shade);
  --ion-color-tint: var(--ion-color-shiawasedo-primary-tint);
}

.ion-color-shiawasedo-danger {
  --ion-color-base: var(--ion-color-shiawasedo-danger);
  --ion-color-base-rgb: var(--ion-color-shiawasedo-danger-rgb);
  --ion-color-contrast: var(--ion-color-shiawasedo-danger-contrast);
  --ion-color-contrast-rgb: var(--ion-color-shiawasedo-danger-contrast-rgb);
  --ion-color-shade: var(--ion-color-shiawasedo-danger-shade);
  --ion-color-tint: var(--ion-color-shiawasedo-danger-tint);
}

.ion-color-gold {
  --ion-color-base: var(--ion-color-gold);
  --ion-color-base-rgb: var(--ion-color-gold-rgb);
  --ion-color-contrast: var(--ion-color-gold-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gold-contrast-rgb);
  --ion-color-shade: var(--ion-color-gold-shade);
  --ion-color-tint: var(--ion-color-gold-tint);
}

.ion-color-vk {
  --ion-color-base: var(--ion-color-vk);
  --ion-color-base-rgb: var(--ion-color-vk-rgb);
  --ion-color-contrast: var(--ion-color-vk-contrast);
  --ion-color-contrast-rgb: var(--ion-color-vk-contrast-rgb);
  --ion-color-shade: var(--ion-color-vk-shade);
  --ion-color-tint: var(--ion-color-vk-tint);
}

.ion-color-mailru {
  --ion-color-base: var(--ion-color-mailru);
  --ion-color-base-rgb: var(--ion-color-mailru-rgb);
  --ion-color-contrast: var(--ion-color-mailru-contrast);
  --ion-color-contrast-rgb: var(--ion-color-mailru-contrast-rgb);
  --ion-color-shade: var(--ion-color-mailru-shade);
  --ion-color-tint: var(--ion-color-mailru-tint);
}

.ion-color-green {
  --ion-color-base: var(--ion-color-green);
  --ion-color-base-rgb: var(--ion-color-green-rgb);
  --ion-color-contrast: var(--ion-color-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-green-shade);
  --ion-color-tint: var(--ion-color-green-tint);
}

.logo {
  width: 50%!important;
  margin: 20px auto;
  clear: both;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

ion-content {
  padding-top: 0;
}
.choose-lang {
ion-button {
&.ion-color-default {
   color: #333;
   box-shadow: none;
   border: none;
 }
}
}
.logo-item {
  margin: 50px 0;
}
.spin {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cart-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.scan-modal {
  background: transparent;
.modal-wrapper {
  background: transparent;
}
}
.sign-margin {
  margin-top: 15px;
}
.order-header {
  margin-top: 50px;
}
.error-message {
  display: none;
  color: red;
}

.error-message {
  display: none;
  color: red;
}

/* ✨ The magic ✨ */
form.errors {
:invalid {
  border-color: red;
}
.error-message {
  display: block;
}
}

.app-menu {
  --width: 85%;
}
.grey-input {
  background: rgb(238, 238, 238);
  border-radius: 20px;
  padding: 2px 20px!important;
}
.text-error {
  color: var(--ion-color-shiawasedo-danger)!important;
}
.text-success {
  color: var(--ion-color-green)!important;
}

.summus-logo .shiawasedo-logo-circle, .summus-logo .shiawasedo-brand-en, .summus-logo .summus-brand {
  fill: var(--ion-color-shiawasedo);
}