
#container {
  padding: 0 var(--padding-start);
}
ion-segment-button {
  font-size: 100%;
  overflow: inherit;
  white-space: normal;
}
.item-block {
  margin: 5px 0 50px;
}
.floating-item {
  margin-top: 10px;
  ion-input, ion-textarea {
    margin: 5px 0 5px;
  }
  .top-label {
    white-space: normal;
    font-size: 16px;
    font-weight: 600;
  }
}
.text-success, .text-error {
  font-size: 14px;
  z-index: 100;
  margin: 0;
}
.wallet-case {
  font-size: 1.2rem;
}
p {
  margin: 0 0 15px;
}
.balance-wrapper {
  padding: 30px 7px 50px;
  margin-left: -7px;
  overflow: hidden;
  .balance-block {
    float: left;
    border-radius: 6px;
    line-height: 1.5;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, .75);
    padding: 20px;
    .balance {
      font-size: 2em;
      line-height: 100%;
      font-weight: bold;
    }
  }
}
ion-button {
  margin-bottom: 40px;
}
