
.feed-footer {
  ion-icon  {
    font-size: 22px;
    line-height: 1;
    padding: 8px 0;
    color: inherit;
  }
  .ion-float-right {
    font-size: 38px;
    padding: 0;
    &.price-button {
      border-radius: 24px;
      font-size: 14px;
      padding: 8px 10px;
      margin-top: 2px;
      background-color: #333;
      color: #fff;
      &.discount {
        background: #ba0c3f;
        color: #fff;
      }
      &.white-theme {
        background-color: #fff;
        color: #333;
      }
    }
  }
  .shared {
    margin: 0 8px -5px 0;
  }
  .liked {
    margin: 0 8px -7px 0;
  }
  .real-price {
    font-size: 80%;
    text-decoration: line-through;
  }
  .product-btn {
    position: absolute;
    bottom: 0;
    &.liked {
      left: 30px;
    }
  }
}
