
  ion-card {
    &.active {
      background: #aec7ff!important;
      border: 2px solid #3948c1;
      ion-card-content {
        background: #aec7ff!important;
      }
      ion-item::part(native) {
        background-color: #aec7ff!important;
      }
    }
  }
