
.buttons-wrapper {
  height: 45px;
  ion-buttons {
    ion-button {
      width: 34%!important;
      &:first-child, &:last-child {
        width: 33%!important;
      }
    }
  }
}
