.title {
  font-size: 2rem;
  color: var(--ion-color-shiawasedo);
  display: block;
  width: 100%;
  font-weight: 300;
}
h2 {
  &.title {
    font-size: 1.75rem;
  }
}
// Лайфхак для отступов
$sizes: 0, 5, 10, 15, 20, 30, 40, 50;
@each $size in $sizes {
  .mt-#{$size} {
    margin-top: #{$size}px!important;
  }
  .mb-#{$size} {
    margin-bottom: #{$size}px!important;
  }
}
hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: .25;
  &.not-lines {
    background: none!important;
  }
}

.input-block-with-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  ion-icon {
    max-width: 100px;
    margin-right: 10px;
    font-size: 120%;
  }
}

.err {
  color: #FF0044;
}