
.scanner-buttons {
  margin: 0;
  position: absolute;
  bottom: 100px;
  width: 100vw;
  height: 50px;
  z-index: 11;
}

.scan-box {
  border: 2px solid #fff;
  box-shadow: 0 0 0 100vmax rgba(0, 0, 0, 0.5);
  content: '';
  display: block;
  left: 50%;
  height: 200px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  .scan-red-line {
    position: absolute;
    top: 50%;
    margin-top: -1px;
    border-top: 2px solid var(--ion-color-gift);
    width: 100%;
  }
}
ion-content{
  --background: transparent;
}
