
  .card-title {
    font-size: 20px;
  }
  .card-order-success {
    h2 {
      font-size: 18px;
      font-weight: 600;
      color: #323232;
      margin-bottom: 10px;
    }
  }
  .card-order-success-text {
    font-size: 16px!important;
  }
  .total-amount {
    font-size: 170%;
    font-weight: 700;
  }
