.ion-text-wrap {
  font-size: 17px!important;
}
.currency-text {
  font-size: 170%!important;
}
.currency-card {
  margin: 10px 16px!important;
  &:last-child {
    margin-bottom: 20px;
  }
  ion-item {
    border: 2px solid #fff;
    border-radius: 8px;
    &.currency-checked {
      --background: #aec7ff !important;
      border: 2px solid #3948c1;
    }
  }
}