
.row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  .col {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  align-items: center;
}
