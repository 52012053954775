
.profile-email {
  font-size: 120%;
  text-align: center;
  margin: 20px 0;
  p {
    margin-top: 5px;
    font-weight: bold;
  }
}
ion-item {
  min-height: 90px;
}
ion-button {
  margin: 20px 5px 50px;
}
.not-opacity-disabled {
  * {
    opacity: 1!important;
  }
}
