
  ion-label {
    margin: 0;
  }
  ion-list {
    &.is-gift {
      background: #ffe5eb;
      ion-item {
        --ion-background-color: transparent !important;
        &:last-child {
          margin-top: 50px;
        }
      }
    }
    border-bottom: 1px solid #bbbbbb;
    padding: 39px 0 0;
    ion-item {
      &:first-child {
        margin-bottom: 12px;
      }
      &:last-child {
        margin-bottom: 20px;
      }
    }
  }
  .order-badge {
    position: relative;
    top: -12px;
  }
  .pull-right {
    float: right;
  }
  ion-item:last-child {
    --min-height: 40px;
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
  }
  ion-button {
    --padding-start: 20px;
    --padding-end: 20px;
    --padding-top: 15px;
    --padding-bottom: 15px;
    font-size: 18px;
  }
  .cart-image {
    margin-right: 10px;
  }
  .total-price-wrapper {
    font-size: 18px;
    text-align: right;
    padding-right: 15px;
    margin: 40px 0 10px;
    .total-price {
      font-size: 110%;
      font-weight: bold;
      color: var(--ion-color-shiawasedo);
    }
  }
  .giant-gift {
    margin: 7px 0 20px;
    display: block;
    padding-left: 20px;
  }
  .subscription-box {
    margin: 8px 5px 20px 20px;
  }
  .subscription-surprise {
    margin: 0 5px 20px 20px;
    font-weight: 800;
  }
  .subscription-number {
    margin: -15px 5px 20px 20px;
  }
