
.cart-label {
  white-space: normal;
}
.price-text {
  color: var(--ion-color-shiawasedo);
  font-size: 18px;
}
ion-note {
  font-size: 16px;
  color: inherit;
  ion-input {
    width: 80px;
  }
}
.item-inner {
  padding-inline-end: 0!important;
}
.delete-product {
  font-size: 27px;
  margin-top: 10px;
}
.cart-list {
  h5, p {
    margin: 0!important;
  }
  item-item {
    &:last-child {
      .item-inner {
        border: 0;
      }
    }
  }
}
ion-thumbnail {
  width: 80px;
  height: 80px;
  margin: 0 0 0 -10px;
}
.product-real-price {
  text-decoration: line-through;
  color: #333!important;
  font-weight: normal;
  margin: 15px 0 0!important;
}
.promo-code-header {
  line-height: 1;
  padding-top: 5px;
}
