
  img {
    display: block;
    object-fit: contain;
    object-position: 50% 50%;
    width: 100%;
    height: 100%;
    &.img-load-error {
      width: 50%!important;
      margin: 0 auto!important;
    }
    &.img-load-height {
      width: 50%;
      height: 200px;
      margin: 0 auto!important;
    }
    &.img-cart {
      height: 70px!important;
      min-height: 70px!important;
    }
  }

