
.hcs {
  display: flex;
  flex-direction: row;
  align-items: center;
  .logo-image {
    width: 35px;
    margin-right: 10px;
  }
}
//makes header bigger site wide on ionic serve
ion-navbar.toolbar {
  min-height: 110px!important;
}
//on Android
ion-navbar.toolbar-md {
  min-height: 80px!important;
}
#search {
  .searchbar-input.sc-ion-searchbar-md {
    padding: 6px 6px 6px 30px;
  }
  .searchbar-search-icon.sc-ion-searchbar-md {
    left: 6px;
  }
}
ion-segment {
  margin-bottom: 5px;
}
:host {
  //--padding-bottom: 10px;
  //--min-height: 40px;
}
