
@import "~vue3-carousel/dist/carousel.css";
.carousel__pagination-button {
  margin: 5px;
  width: 70px!important;
  height: 2px;
  border: 0;
  cursor: pointer;
  background-color: #bbbbbb;
}
.carousel__pagination-button--active {
  background-color: var(--ion-color-shiawasedo-shade);
}
.banner-body {
  color: #323232;
  background: #fff;
  width: 100%;
  transition: height 0.25s ease-in;
  z-index: 1000;
  overflow: hidden;
  border-bottom: 1px solid #eee;
  .carousel {
    height: 100%;
    .carousel__viewport {
      height: 100%;
      .carousel__track {
        height: 100%;
      }
    }
    .carousel__slide {
      text-align: left;
      display: block;
    }
    .carousel__pagination {
      position: absolute;
      bottom:0;
      margin: 0;
      width: 100%;
      padding-left: 0;
    }
  }
  ion-buttons {
    position: absolute;
    right: 6px;
    font-size: 30px;
    margin-top: -5px;
  }
  h4 {
    padding: 0;
    margin: 0 0 7px;
    line-height: 20px;
  }
  .img-block {
    text-align: center;
    height: calc(100% - 60px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center center;
    background-size: contain;
    transition: background-position 0.15s ease-in;
    background-repeat: no-repeat;
  }
}
