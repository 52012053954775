
#logo-block {
  padding: 10px 0;
  text-align: center;
  font-size: 100px;
  line-height: 1;
  .logo {
    margin: 0;
  }
}
.sign-in-with-apple {
  --border-color: var(--ion-color-dark);
  --border-width: 1px;
  --border-style: solid;
}

