
h4 {
  font-size: 14px;
}
.like-btn {
  z-index: 1;
  bottom: 20px;
  left: 10px;
  position: fixed;
  ion-button {
    background-color: var(--ion-color-shiawasedo);
    border-radius: 50%;
    width: 35px!important;
    height: 35px!important;
    padding: 0!important;
    vertical-align: middle;
    padding: 0!important;
  }
}
.timeline-inside-content {
  .timeline-inside-data {
    .timeline-header {
      position: relative;
      width: 100%;
      height: calc(100vw * 1.25);
      padding: 50px 5% 5%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      h1,h4 {
        margin: 0 0 5px;
      }
      .inside-share {
        position: absolute;
        bottom: 20px;
      }
    }
    .timeline-body {
      z-index: -1;
      padding: 10px 5% 10px;
      font-size: 18px;
    }
  }
}
