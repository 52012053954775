
.product-currencies {
  margin-bottom: 20px;
}
.price-text {
  margin-top: 10px;
  font-size: 28px;
  color: var(--ion-color-shiawasedo);
}
.product-name {
  margin-top: 16px;
  white-space: normal;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  color: var(--ion-color-shiawasedo);
  display: block;
}
.product-wrapper {
  font-size: 18px;
  h4 {
    color: var(--ion-color-shiawasedo);
    margin: 45px 0 15px;
  }
}
.product-real-price {
  text-decoration: line-through;
  color: #333!important;
  font-weight: normal;
  margin: 15px 0 0!important;
}
.product-short-description {
  margin: 15px 0;
}
.product-long-description {
  margin: 45px 0 0;
}
.almost-out-of-stock {
  margin: 15px 0;
}
